import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import FormProvider, { RHFUpload, RHFCheckbox, RHFTextField } from 'components/hook-form';
import { CustomFile } from 'components/upload';
import { FormHelperText, Stack } from '@mui/material';
import RHFMarkdown from 'components/hook-form/RHFMarkdown';
import { createPost, updatePost } from 'redux/slices/post';
import { IErrorForlikeResponse } from '../../../@types/error';
import { useRouter } from 'next/router';
import { useSelector } from 'redux/store';

// ----------------------------------------------------------------------
type Props = {
  // currentData: IPost | null;
};
type FormValuesProps = {
  datePost: Date;
  content: string;
  images: CustomFile[] | string[];
  isNotification: boolean;
  isShow: boolean;
  expiredAt: Date;
};

export default function PostFormCreate({}: Props) {
  const router = useRouter();
  const { currentPost } = useSelector((state) => state.post);
  const [errors, setErrors] = useState<IErrorForlikeResponse[]>([]);

  const BankSchema = Yup.object().shape({
    content: Yup.string().required('Thông tin bắt buộc'),
    images: Yup.mixed().required('Thông tin bắt buộc').nullable(true),
    isNotification: Yup.boolean(),
    isShow: Yup.boolean(),
    datePost: Yup.date()
      .required('Thông tin bắt buộc')
      .min(new Date(), 'Ngày đăng phải lớn hơn ngày hiện tại'),
    expiredAt: Yup.date()
      .required('Thông tin bắt buộc')
      .min(Yup.ref('datePost'), 'Ngày hết hạn phải lớn hơn ngày đăng'),
  });

  const defaultValues = {
    datePost: new Date(currentPost?.datePost ?? new Date()),
    content: currentPost?.content || '',
    images: currentPost?.filePaths || [],
    isNotification: currentPost?.isNotification === false ? false : true,
    isShow: currentPost?.isShow === false ? false : true,
    expiredAt: new Date(currentPost?.expiredAt ?? new Date()),
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(BankSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmitForm = async (data: FormValuesProps) => {
    let res;
    if (currentPost?.id) res = await updatePost(data);
    else res = await createPost(data);

    if (res[1]) {
      setErrors(res[1].errors);
    } else {
      setErrors([]);
      router.push(router.asPath);
    }
  };
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFile = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      if (newFile) {
        setValue('images', newFile);
      }
    },
    [setValue]
  );
  const handleRemoveFile = () => {
    setValue('images', []);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitForm)}>
      <Stack spacing={1} sx={{ mb: 2 }}>
        {errors.map((error, index) => (
          <FormHelperText error key={index}>
            <li>{error.message}</li>
          </FormHelperText>
        ))}
      </Stack>
      <Stack spacing={1}>
        <RHFTextField size="small" name="datePost" label="Ngày đăng" type="datetime-local" />
        <RHFTextField size="small" name="expiredAt" label="Ngày hết hạn" type="datetime-local" />
        <RHFMarkdown name="content" style={{ minHeight: 300 }} />
        <RHFUpload
          name="images"
          multiple
          maxSize={3145728}
          onDrop={handleDrop}
          onDelete={handleRemoveFile}
        />
        <RHFCheckbox name="isNotification" label="Hiển thị thông báo." />
        <RHFCheckbox name="isShow" label="Hiển thị bài viết" />
      </Stack>
      <Stack alignItems="flex-end" sx={{ my: 2 }}></Stack>
      <LoadingButton
        fullWidth
        color="primary"
        size="small"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Lưu
      </LoadingButton>
    </FormProvider>
  );
}
