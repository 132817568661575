import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';

import Iconify from 'components/iconify';
import CreateDialog from './CreateDialog';
import { useSelector } from 'redux/store';
import { IPost } from '../../../@types/post';
import ItemContent from './ItemContent';
import { AuthUserType } from '../../../@types/global';
import { deletePost, setOpenDialogForm } from 'redux/slices/post';
import { setError } from 'redux/slices/global';
import { useRouter } from 'next/router';

export default function CardItemContent({
  SiteName,
  posts,
  user,
}: {
  SiteName: string;
  posts: IPost[];
  user?: AuthUserType;
}) {
  const router = useRouter();
  const { isOpenDialogForm } = useSelector((state) => state.post);

  const handleDeletePost = async (post: IPost) => {
    const [_, error] = await deletePost(post.id);
    if (error) {
      setError(error.errors);
    } else {
      router.reload();
    }
  };

  return (
    <Box>
      <Card sx={{ mb: 2, p: 1 }} style={{ display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Avatar
            src={'/favicon/icon/128.png'}
            sx={{
              margin: '8px 8px 8px 8px !important',
              cursor: 'pointer',
              p: '5px',
              border: 'solid 1px #1B5AA9',
            }}
          />
          <TextField
            onClick={() => {
              if (user?.permission) {
                setOpenDialogForm(true);
              }
            }}
            style={{ marginLeft: 10 }}
            size="small"
            fullWidth
            hiddenLabel
            placeholder={(user?.fullname ?? '') + ' ơi, Bạn nghĩ gì thế?'}
          />
        </Stack>

        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Box style={{ display: 'flex' }}>
          <Button style={{ display: 'flex', flex: 1 }}>
            <Iconify icon="ic:baseline-video-call" width={25} sx={{ mr: 0.5 }} color="#FF4842" />
            <Typography variant="subtitle2">Video trực tiếp</Typography>
          </Button>
          <Button style={{ display: 'flex', flex: 1 }}>
            <Iconify icon="mdi:image-multiple" width={25} sx={{ mr: 0.5 }} color="#54D62C" />
            <Typography variant="subtitle2">Ảnh/Video</Typography>
          </Button>
          <Button style={{ display: 'flex', flex: 1 }}>
            <Iconify icon="ic:outline-emoji-emotions" width={25} sx={{ mr: 0.5 }} color="#FFC207" />
            <Typography variant="subtitle2">Cảm xúc/Hoạt động</Typography>
          </Button>
        </Box>
      </Card>
      <Stack spacing={2}>
        {posts.map((item) => (
          <ItemContent
            key={item.id}
            SiteName={SiteName}
            post={item}
            user={user}
            onDelete={handleDeletePost}
          />
        ))}
      </Stack>
      <CreateDialog open={isOpenDialogForm} onClose={() => setOpenDialogForm()} />
    </Box>
  );
}
