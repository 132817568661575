// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
//

import Editor from 'react-markdown-editor-lite';
import Markdown from 'components/markdown/Markdown';
import { useRef } from 'react';
import { HtmlType } from 'react-markdown-editor-lite/cjs/editor/preview';
import { EditorConfig } from 'react-markdown-editor-lite/cjs/share/var';

// ----------------------------------------------------------------------

interface Props extends EditorConfig {
  name: string;
  id?: string;
  defaultValue?: string;
  value?: string;
  style?: React.CSSProperties;
  autoFocus?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  className?: string;
  config?: any;
  plugins?: string[];
  onChange?: (
    data: {
      text: string;
      html: string;
    },
    event?: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onScroll?: (e: React.UIEvent<HTMLTextAreaElement | HTMLDivElement>, type: 'md' | 'html') => void;
}

export default function RHFMarkdown({ name, ...other }: Props) {
  const { control } = useFormContext();
  const mdEditor = useRef(null);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => {
        const handleEditorChange = ({ html, text }: { html: string; text: string }) => {
          field.onChange(text);
        };
        return (
          <Editor
            id={name}
            ref={mdEditor}
            value={field.value}
            onChange={handleEditorChange}
            renderHTML={(text) => <Markdown children={text} />}
            // error={!!error}
            // helperText={
            //   <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
            //     {error?.message}
            //   </FormHelperText>
            // }
            style={{ maxHeight: 500 }}
            {...other}
          />
        );
      }}
    />
  );
}
