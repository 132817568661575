// @mui
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// layouts
import DashboardLayout from '../layouts/dashboard';
// components
import Content from 'sections/@dashboard/app/Content';
// ----------------------------------------------------------------------
import { useTheme } from '@mui/material/styles';
import { fNumber } from 'utils/formatNumber';
import { GetServerSidePropsContext } from 'next';
import { IPost } from '../@types/post';
import axiosInstance from 'utils/axios';
import { useSelector } from 'redux/store';
import { IConfig } from '../@types/config';
import { ItemSupportValue } from './support';
import { ConfigKey } from 'utils/constant';
import Image from 'components/image/Image';
import Link from 'next/link';

HomePage.getLayout = (page: React.ReactElement) => <DashboardLayout>{page}</DashboardLayout>;

// ----------------------------------------------------------------------
export default function HomePage({
  SiteName,
  posts,
  dataConfigs,
}: {
  SiteName?: string;
  posts: IPost[];
  total: number;
  dataConfigs: IConfig[];
}) {
  const theme = useTheme();
  const { user } = useSelector((state) => state.global);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <Container maxWidth={'md'}>
            <Content posts={posts} user={user} SiteName={SiteName ?? ''} />
          </Container>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <Box style={{ paddingTop: 15, paddingLeft: 15 }}>
              <Typography variant="subtitle1"> Thông tin</Typography>
            </Box>
            <Box style={{ padding: 15 }}>
              <Box
                style={{
                  border: '1px solid #dde0e5',
                  borderRadius: 8,
                  marginBottom: 10,
                  padding: 8,
                }}
              >
                <Typography variant="subtitle1">Số dư hiện tại</Typography>

                <Typography
                  variant="h6"
                  style={{ textAlign: 'center', color: theme.palette.primary.main }}
                >
                  {user && fNumber(user.coin)} Coin
                </Typography>
              </Box>
              <Box
                style={{
                  border: '1px solid #dde0e5',
                  borderRadius: 8,
                  marginBottom: 10,
                  padding: 8,
                }}
              >
                <Typography variant="subtitle1">Tổng tiền đã nạp</Typography>
                <Typography
                  variant="h6"
                  style={{ textAlign: 'center', color: theme.palette.primary.main }}
                >
                  {user && fNumber(user.coinrecharge)} Coin
                </Typography>
              </Box>
              <Box
                style={{
                  border: '1px solid #dde0e5',
                  borderRadius: 8,
                  marginBottom: 10,
                  padding: 8,
                }}
              >
                <Typography variant="subtitle1">Kênh hỗ trợ</Typography>
                <Grid container justifyContent="center" spacing={2}>
                  {dataConfigs
                    .filter((f) => f.name === ConfigKey.Support)
                    .map((item, index) => {
                      let content: ItemSupportValue | null = null;
                      try {
                        content = JSON.parse(item.value);
                      } catch (e) {
                        //
                      }
                      if (!content) return null;

                      return (
                        <Grid key={content.title} item xs={12} md={4}>
                          <Tooltip title={content.title}>
                            <Link href={content.link} target="_blank">
                              <Image
                                src={content.linkIcon}
                                style={{ height: 60, objectFit: 'contain' }}
                              />
                            </Link>
                          </Tooltip>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export async function getServerSideProps({ query, req, res }: GetServerSidePropsContext) {
  try {
    res.setHeader('Cache-Control', 'public, s-maxage=3600, stale-while-revalidate=60');

    const { page, limit, search } = query;
    const cookies = req?.headers.cookie ? `${req.headers.cookie};` : '';
    const config = {
      headers: {
        Cookie: cookies,
      },
    };
    const { data } = await axiosInstance.get<{ items: IPost[]; total: number }>(
      `/api/v1/post?page=${page ?? 0}&limit=${limit ?? 10}&search=${search ?? ''}`,
      config
    );

    return { props: { posts: data.items, total: data.total } };
  } catch (e) {
    return { props: { posts: [], total: 0, errors: e.errors } };
  }
}
