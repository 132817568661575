import { Dialog, DialogTitle, DialogActions, DialogContent, Card, Button } from '@mui/material';
import CreateForm from './CreateForm';
import { IPost, IPostCreate } from '../../../@types/post';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function CreateDialog({ open, onClose }: Props) {
  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle sx={{ pb: 2 }}>Tạo bài viết</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        <Card sx={{ mb: 3 }} style={{ padding: 15 }}>
          <CreateForm />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Hủy
        </Button>
      </DialogActions>
    </Dialog>
  );
}
